let curl = "http://192.168.1.143:8099/"
export default {
	makeUrl: curl + 'jeecg-boot/makeIdcard/makeIdCard',      //获取有水印的
    getIdCard: curl + 'jeecg-boot/makeIdcard/getIdCard',    //下单
    queryOrder: curl + 'jeecg-boot/makeIdcard/queryOrder'   //获取
}


// import axios from "axios";

// var baseURL = "/apis"; //开发环境

// // https://api.zheyings.cn/idcardv5/


// const http = axios.create({
//   baseURL,
//   timeout: 5000,
// });


// export default http;