import Vue from "vue";
import VueRouter from "vue-router";

// Vue.use(VueRouter);

const routes = [
    //首页
    {
        path: '/',
        name: 'home',
        component: () => import('../views/home.vue')
    },
];

const router = new VueRouter({
    mode: "history",
    // base: 'process.env.BASE_URL',
    routes,
});


Vue.use(VueRouter);
// const router = new VueRouter({ routes })
router.beforeEach((to, from, next) => {
    // 让页面回到顶部
    document.documentElement.scrollTop = 0
    // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
    next()
})


//   router.beforeEach((to, from, next) => {
//     if (to.matched.some((r) => r.meta.requireAuth)) {
//       if (store.state.isLogin) {
//         next();
//       } else {
//         next({
//           path: "/login",
//           query: { redirect: to.fullPath },
//         });
//       }
//     } else {
//       next();
//     }
//   });

export default router;