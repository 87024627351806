<template>
  <div id="app">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
export default {
    name: 'App',
    data(){
        return{

        }
    },
    components:{
    }
}
</script>

<style lang="less">
@media all and ( max-width: 1200px) {
    .sign{
        width: 100% !important;
    }
}
html,body,#app{
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  padding-right: 0 !important;
}
/deep/ .el-popup-parent--hidden{
    overflow: auto;
}
.flex {
    display: flex;
}
.sign {
    width: 1200px;
    margin: 0 auto;
}
//修改elementUi Message
// .el-message{
//     // background-color: rgba(20, 45, 74, 0.3);;
//     top: 80px !important;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     min-width: 150px;
// }
</style>
