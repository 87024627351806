import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";

Vue.config.productionTip = false

//引入全局样式
import "./assets/public.css"



//引入element ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

//二次封装 element message
import { message } from "./utils/resetMessage"
Vue.prototype.$message = message;

import api from "./api"
Vue.prototype.$api = api

import axios from 'axios'          //引入axios
// Vue.prototype.$http = axios;      //把axios挂载到vue上

Vue.prototype.$http = axios
axios.defaults.baseURL = '/api'

import download from './utils/downLoad'
Vue.use(download)

Vue.directive('throttle', {                     //js 节流 点击事件
  // 需要传递数据
      // fn               节流事件(必传) (function)
      // delay            节流时间(默认值 200) (Number)
      // event            事件类型(默认值 'click') (String)
  mounted(el, binding) {
      // 至少需要回调函数以及监听事件类型
      if (typeof binding.value.fn !== 'function' ) return;
      let delay = 200;
      let event = 'click';         // 默认事件类型
      el.timer = null;
      el.handler = function() {
          if (el.timer) return;
          el.timer = setTimeout(() => {
              binding.value.fn.apply(this, arguments)
              el.timer = null;
          }, binding.value.delay || delay);
      }
      el.addEventListener(binding.value.event || event, el.handler)
  },
  // 元素卸载前也记得清理定时器并且移除监听事件
  beforeMount(el, binding) {
      if (el.timer) {
          clearTimeout(el.timer);
          el.timer = null;
      }
      el.removeEventListener(binding.value.event || event, el.handler)
  }
})



new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
